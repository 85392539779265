import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { ISecurityState } from './actions/security.actions';
import { SettingState } from './actions/settings.actions';
import { ThemeState } from './actions/themes.actions';

// import { persistedState, saveState } from './persisted.store';
// import { ISocketsState } from './actions/sockets.actions';
import portalApi from './api/portalApi';

import securityReducer from './reducers/security.reducer';
import settingsReducer from './reducers/settings.reducer';
// import { socketsReducer } from './reducers/sockets.reducers';
import themesReducer from './reducers/themes.reducers';

import settings from './middlewares/settings.middleware';
// import sockets from './middlewares/sockets.middleware';
import themes from './middlewares/themes.middleware';


export interface ApplicationState {
  security: ISecurityState,
  settings: SettingState,
//  sockets: ISocketsState,
  theme: ThemeState,
}

export const store = configureStore({
  reducer: {
    security: securityReducer,
    settings: settingsReducer,
//    sockets: socketsReducer,
    theme: themesReducer,
    [portalApi.reducerPath]: portalApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(portalApi.middleware)
      .concat(settings)
//      .concat(sockets)
      .concat(themes)
});


// function configureStore() {
//     const store = createStore(
//         reducers,
//         persistedState, // second argument overrides the initial state
//         applyMiddleware(
//             ...getDefaultMiddleware(),
//             ...middlewares
//         )
//     );

//     // add a listener that will be invoked on any state change
//     store.subscribe(() => {
//         saveState(store.getState());
//     });

//     // Update the initial theme
//     updateTheme(store.getState())

//     // Debugging subscription
//     store.subscribe(() => {
//         console.log('store-state', store.getState());
//     });

//     return store;
// }

// export type AppThunk<ReturnType = void> = ThunkAction<
//   ReturnType,
//   RootState,
//   unknown,
//   Action<string>
// >;


export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>;

setupListeners(store.dispatch);
