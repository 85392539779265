import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { ThemeState, ChangeThemeAction } from '../actions/actions';

const initialState: ThemeState = {
    path: ''
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState: initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<ChangeThemeAction>) => {
      state.path = action.payload.path;
    },
  }
});

export const { changeTheme } = themeSlice.actions;
export const themeState = (state: RootState) => state.settings;
export default themeSlice.reducer;

// const themesReducer = (state = initialState, action: ChangeThemeAction): ThemeState => {
//     switch (action.type) {
//         case CHANGE_THEME:
//             return {
//                 ...state,
//                 path: action.path
//             }
//         default:
//             return state;
//     }
// }

// export default themesReducer;