export interface ISidebarMenuItem {
    heading?: string;
    name?: string;
    icon?: string;
    translate?: string;
    path?: string;
    label?: {
        value: string | number;
        color: string;
    };
    submenu?: ISidebarMenu;
}

export interface ISidebarMenu extends Array<ISidebarMenuItem> {}

const Menu: ISidebarMenu = [
    {
        heading: 'Main Navigation',
        translate: 'sidebar.heading.HEADER'
    },
    {
        name: 'Overview',
        path: 'overview',
        icon: 'icon-grid',
        translate: 'sidebar.nav.FIELD_MATRIX'
    },
    {
        name: 'placeholder',
        path: 'fields',
        icon: 'fas fa-seedling',
        translate: 'sidebar.nav.FIELDS'
    }
    // ,
    // {
    //     name: 'Components',
    //     icon: 'icon-speedometer',
    //     translate: 'sidebar.nav.menu.COMPONENTS',
    //     label: { value: 4, color: 'info' },
    //     submenu: [
    //         {
    //             name: 'Buttons',
    //             path: '/buttons',
    //             translate: 'sidebar.nav.components.BUTTONS'
    //         },
    //         {
    //             name: 'Forms',
    //             path: 'form-standard',
    //             translate: 'sidebar.nav.components.FORMS'
    //         },
    //         {
    //             name: 'Tables',
    //             path: 'table-standard',
    //             translate: 'sidebar.nav.components.TABLES'
    //         },
    //         {
    //             name: 'Cards',
    //             path: 'cards',
    //             translate: 'sidebar.nav.components.CARDS'
    //         }
    //    ]
    // }
];

export default Menu;
