import React from 'react';
import { useAppDispatch } from '../../store/hooks';
import { toggleSetting } from '../../store/reducers/settings.reducer';
import { TOGGLE_SETTING } from '../../store/actions/settings.actions';

type HeaderProps = {
    // toggleSetting: typeof toggleSetting
    // login: () => void,
    // logout: () => void
};

const Header: React.FC<HeaderProps> = (props) => {
    const dispatch = useAppDispatch();

    const toggleCollapsed = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(toggleSetting({ type: TOGGLE_SETTING, name: 'isCollapsed' }));
        resize()
    }

    const toggleAside = (e: React.MouseEvent) => {
        e.preventDefault();
        dispatch(toggleSetting({ type: TOGGLE_SETTING, name: 'asideToggled' }));
    }

    const resize = () => {
        const event = document.createEvent('HTMLEvents');
        event.initEvent('resize', true, false);
        document.dispatchEvent(event);
    }

return (
    <header className="topnavbar-wrapper">
        { /* START Top Navbar */ }
        <nav className="navbar topnavbar">
            { /* START navbar header */ }
            <div className="navbar-header">
                <a className="navbar-brand" href="#/">
                    <div className="brand-logo">
                        <img className="img-fluid" src="img/logo.png" alt="App Logo" />
                    </div>
                    <div className="brand-logo-collapsed">
                        <img className="img-fluid" src="img/logo-single.png" alt="App Logo" />
                    </div>
                </a>
            </div>
            { /* END navbar header */ }

            { /* START Left navbar */ }
            <ul className="navbar-nav mr-auto flex-row">
                <li className="nav-item">
                    { /* Button used to collapse the left sidebar. Only visible on tablet and desktops */ }
                    <a href="#/" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ toggleCollapsed }>
                        <em className="fas fa-bars"></em>
                    </a>
                    { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                    <a href="#/"  className="nav-link sidebar-toggle d-md-none" onClick={ toggleAside }>
                        <em className="fas fa-bars"></em>
                    </a>
                </li>
            </ul>
            { /* END Left navbar */ }
            { /* START Right Navbar */ }
            <ul className="navbar-nav flex-row">
                {/* <button onClick={this.props.login}>login</button>
                <button onClick={this.props.logout}>login</button> */}
            </ul>
            { /* END Right Navbar */ }
        </nav>
        { /* END Top Navbar */ }
    </header>
    );
}

export default Header;