import React, { Suspense, lazy } from 'react';
import { useAuth, User } from 'oidc-react';
import { useDispatch } from 'react-redux';
import { ISecurityState } from './store/actions/actions';
import { ChangeUser } from './store/reducers/security.reducer';
import { withRouter, Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';
import Base from './components/Layout/Base';
// import Sockets from './components/Layout/Sockets';
// import { useGetUpdatesQuery } from './store/api/socketsApi';

/* Used to render a lazy component with react-router */
// const useQuery = () => new URLSearchParams(useLocation().search);
// const reload = () => window.location.reload();
const waitFor = (Tag: React.LazyExoticComponent<any>) => (props: any) => <Tag {...props} />;
const Info = lazy(() => import('./components/Elements/Info'));
const NotLoggedIn = lazy(() => import('./components/Layout/NotLoggedIn'));
const Overview = lazy(() => import('./components/Overview/Overview'));
const Fields = lazy(() => import('./components/Fields/Fields'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
// const listofPages: Array<string> = [
//     /* See full project for reference */
// ];

const Routes = ({ location }: RouteProps) => {
    const currentKey = location!.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };
    const dispatch = useDispatch();
    const auth = useAuth();

    // This is a little messy but I need to make sure the auth token is properly available
    // when we get into the api calls. Since we don't have persistence on the security context
    // this is how I force it to be up to date in the state currently.
    const updateUser = (user: User | null) => {
      const usr: ISecurityState = {
        token: user! && user.id_token ? user.id_token : '',
        user: user?.profile?.name ? user.profile.name : '',
        email: user?.profile?.email ? user.profile.email : ''
      };

      dispatch(ChangeUser(usr));
    }

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'
    const animationName = 'rag-fadeIn'

    if (auth && auth.userData) {
        //console.log('option 1 - user is authenticated');
        //console.log(auth);
        updateUser(auth.userData);
        return (
            // Layout component wrapper
            <Base>
                {/* <Sockets> */}
                    <TransitionGroup>
                        <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                            <div>
                                <Suspense fallback={<PageLoader />}>
                                    <Switch location={location}>
                                        <Route path="/info" component={waitFor(Info)} />

                                        <Route path="/overview" component={waitFor(Overview)} />
                                        <Route path="/fields" component={waitFor(Fields)} />

                                        <Redirect to="/fields" />
                                    </Switch>
                                </Suspense>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                {/* </Sockets> */}
            </Base>
        );
    } else {
        //console.log('option 2 - user is not authenticated');
        //console.log('auth', auth);
        updateUser(null);
        return (
            // Layout component wrapper
            <Base>
                <TransitionGroup>
                    <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                        <div>
                            <Suspense fallback={<PageLoader />}>
                                <Switch location={location}>
                                    <Route path="/info" component={NotLoggedIn} />
                                </Switch>
                            </Suspense>
                        </div>
                    </CSSTransition>
                </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);
