import { REACT_APP_FIELDS_URL } from '../../AppConfig';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApplicationState } from '../store';

// The only API we create. Then slices are injected from specific files
// This assumes all of our APIs come from a single starting URL.
const portalApi = createApi({
  reducerPath: 'portalapi',
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_FIELDS_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as ApplicationState).security.token;
      if (token) { headers.set('Authorization', `Bearer ${token}`); }
      headers.set('Content-Type', 'application/json');
      return headers
    },
  }),
  tagTypes: ['field', 'prediction'],
  endpoints: (builder) => ({
    // See endpoint specific API files
  }),
});

// export const { } = portalApi;
export default portalApi;
