import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const translation: string ='translations';

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next )
    .init({
        fallbackLng: 'en',
        backend: {
            loadPath: 'locales/{{lng}}/{{ns}}.json',
        },
        // have a common namespace used around the full app
        ns: [translation],
        defaultNS: translation,
        debug: true,
        react: {
            wait: true,
            useSuspense: false
        }
    });


export default i18n;