import 'core-js/es/string';
import 'core-js/es/array';
import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/promise';
import 'core-js/es/object';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './App';

import './i18n';
import { store } from './store/store';

ReactDOM.render(
        <Provider store={store}>
            <App />
        </Provider>,

    document.getElementById('app')
);
