import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISecurityState } from '../actions/actions';
import { RootState } from '../store';

const initialState: ISecurityState = {
  token: '',
  user: '',
  email: ''
}

export const securitySlice = createSlice({
  name: 'security',
  initialState: initialState,
  reducers: {
    ChangeUser: (state, action: PayloadAction<ISecurityState>) => {
      state.token = action.payload.token;
      state.email = action.payload.email;
      state.user = action.payload.user;
    },
  },
});

export const { ChangeUser } = securitySlice.actions;
export const securityState = (state: RootState) => state.security;
export default securitySlice.reducer;
