declare const window: any;

const findFirstExistingProperty = (properties: string[], errorMessage: string) => {
    const notEmptyProperties = properties.filter(property => property !== undefined);
    if (notEmptyProperties.length === 0) {
        throw new Error(errorMessage);
    }
    return notEmptyProperties[0];
}

const resolveDynamicName = (name: string) => {
    switch (name) {
        case "PUBLIC_URL":
            return findFirstExistingProperty(
                [window._env_.PUBLIC_URL, process.env.PUBLIC_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_AUTH_URL":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_AUTH_URL, process.env.REACT_APP_AUTH_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_IDENTITY_CLIENT_ID":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_IDENTITY_CLIENT_ID, process.env.REACT_APP_IDENTITY_CLIENT_ID],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_REDIRECT_URL":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_REDIRECT_URL, process.env.REACT_APP_REDIRECT_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_SILENT_REDIRECT_URL":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_SILENT_REDIRECT_URL, process.env.REACT_APP_SILENT_REDIRECT_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_LOGOFF_REDIRECT_URL":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_LOGOFF_REDIRECT_URL, process.env.REACT_APP_LOGOFF_REDIRECT_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_AUDIENCE":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_AUDIENCE, process.env.REACT_REACT_APP_AUDIENCEAPP_AUTH_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_SCOPE":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_SCOPE, process.env.REACT_APP_SCOPE],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_FIELDS_URL":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_FIELDS_URL, process.env.REACT_APP_FIELDS_URL],
                'Failed to resolve dynamic name...'
            );
        case "REACT_APP_SOCKETS":
            return findFirstExistingProperty(
                [window._env_.REACT_APP_SOCKETS, process.env.REACT_APP_SOCKETS],
                'Failed to resolve dynamic name...'
            );
        default:
            return "";
    }
}

export const REACT_APP_AUTH_URL = resolveDynamicName("REACT_APP_AUTH_URL");
export const REACT_APP_IDENTITY_CLIENT_ID = resolveDynamicName("REACT_APP_IDENTITY_CLIENT_ID");
export const REACT_APP_REDIRECT_URL = resolveDynamicName("REACT_APP_REDIRECT_URL");
export const REACT_APP_SILENT_REDIRECT_URL = resolveDynamicName("REACT_APP_SILENT_REDIRECT_URL");
export const REACT_APP_LOGOFF_REDIRECT_URL = resolveDynamicName("REACT_APP_LOGOFF_REDIRECT_URL");
export const REACT_APP_AUDIENCE = resolveDynamicName("REACT_APP_AUDIENCE");
export const REACT_APP_SCOPE = resolveDynamicName("REACT_APP_SCOPE");
export const PUBLIC_URL = resolveDynamicName("PUBLIC_URL");
export const REACT_APP_FIELDS_URL = resolveDynamicName("REACT_APP_FIELDS_URL");
export const REACT_APP_SOCKETS = resolveDynamicName("REACT_APP_SOCKETS");
