import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Log } from 'oidc-client-ts';
import { AuthProvider, AuthProviderProps, User } from 'oidc-react';
import { connect, useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { ISecurityState } from './store/actions/actions';
import { ChangeUser } from './store/reducers/security.reducer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { REACT_APP_AUTH_URL, REACT_APP_IDENTITY_CLIENT_ID, REACT_APP_REDIRECT_URL } from './AppConfig';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";

// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'

//declare var PUBLIC_URL: string;

function App() {
  const dispatch = useDispatch();

  const onSignIn = (userData: User | null): void => {
    // alert('You just signed in, congratz! Check out the console!');
    console.log('You just signed in, congratz! Check out the console!', userData);

    const usr: ISecurityState = {
      token: userData! && userData.id_token ? userData.id_token : '',
      user: userData?.profile?.name ? userData.profile.name : '',
      email: userData?.profile?.email ? userData.profile.email : ''
    };

    console.log('ChangeUser');
    dispatch(ChangeUser(usr));
    window.location.hash = '';
  };

  // const onSignOut = (options?: AuthProviderSignOutProps): void => {
  //   alert('you just signed out!');
  //   console.log('options', options);

  //   const usr: ISecurityState = {
  //     token: '',
  //     user: '',
  //     email: ''
  //   };

  //   dispatch(changeUser(usr))
  // };

  // authority: window._env_.REACT_APP_AUTH_URL,

  Log.setLevel(Log.DEBUG);
  Log.setLogger(console);

  // userStore: new WebStorageStateStore({ store: window.localStorage }),

  const oidcConfig: AuthProviderProps = {
    loadUserInfo: true,
    autoSignIn: true,
    authority: REACT_APP_AUTH_URL,
    clientId: REACT_APP_IDENTITY_CLIENT_ID,
    scope: 'openid profile email',
    responseType: 'code',
    redirectUri: REACT_APP_REDIRECT_URL,
    automaticSilentRenew: true //,
    // silentRedirectUri: REACT_APP_SILENT_REDIRECT_URL
  };

  // specify base href from env varible 'PUBLIC_URL'
  // use only if application isn't served from the root
  // for development it is forced to root only
  // If we host someplace other than the root of a URI then the !development
  // value needs updated to that instance partial path name 'subdir/'
  const basename = process.env.NODE_ENV === 'development' ? '/' : '/';
  console.log('oidcConfig', oidcConfig);

  return (
    <AuthProvider {...oidcConfig} onSignIn={onSignIn} >
      <BrowserRouter basename={basename}>
        <Routes />
      </BrowserRouter>
      <ToastContainer />
    </AuthProvider>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateUser: (state: ISecurityState) => dispatch(ChangeUser(state))
});


export default connect(null, mapDispatchToProps)(App);
